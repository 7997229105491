/* eslint-disable indent */
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Box, makeStyles, Paper } from "@material-ui/core"
import Videos from "../components/videos"
import { P } from "../components/EasyText"
import Link from "../components/Link"

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
  },
  container: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      boxSizing: "border-box",
      overflow: "hidden",
      "&::after": {
        boxSizing: "content-box",
        display: "block",
        content: "''",
        width: `calc(100% + ${2 * theme.spacing(2)}px)`,
        paddingBottom: "calc(56.25% + 2px)" /* 16:9 */,
        marginLeft: -theme.spacing(2),
      },
    },
  },
  ytPaper: {
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
  },
  ytWrap: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      left: 0,
      right: 0,
    },
    paddingBottom: "56.25%" /* 16:9 */,
    height: 0,
    transform: "translateZ(0)",
    minWidth: "100%",
  },
  ytFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transform: "translateZ(0)",
  },
}))

const IndexPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Container maxWidth="md">
        <Box component="main">
          <SEO />

          <Box className={classes.section} clone align="center">
            <Paper variant="outlined" component="section">
              <Link
                to=""
                style={{ margin: "auto" }}
                linkIsButton
                variant="outlined"
                color="secondary"
              >
                Visit the full site with extra videos and content
              </Link>
            </Paper>
          </Box>

          <Videos classes={classes} />
        </Box>
      </Container>
    </Layout>
  )
}

export default IndexPage
